<template>
    <div class="sub_add" v-if="isShow" v-wechat-title="this.title">
        <div class="top">
            <Steps :dataSource="list" :active="0" :class="short_name ==='xrb'?'steps last':'steps'"></Steps>
            <!-- <div class="remark">请您按照步骤提交申请所需要的信息～</div> -->
        </div>
        <div class="content">

            <div class="title">身份及从业信息</div>
            <formPanel v-if="formList.length > 0" :arr="formList[formIndex].form" @confirm="formConfirm" @step="back"></formPanel>

        </div>
    </div>
</template>

<script>
    import {
        Toast
    } from "vant";
    import Steps from '@/components/steps.vue'
    import formPanel from '@/components/form.vue'
    import {
        mapActions,
        mapGetters,
        mapState
    } from 'vuex';
    import {
        checkCellphone,
        isIdCardNo,
        getAge,
        getSexById
    } from '@/utils/utils.js'
    import {
        // provinceCity,
        // hospital,
        StepOne,
        projectDoctor,
        getDoctorForm
    } from '@/api/api'
    export default {
        name: '',
        components: {
            Steps,
            formPanel
        },
        data() {
            return {
                title: ' ',
                isShow: false,
                doctor: {
                    name: '',
                    mibile: ''
                },
                list: [{
                    step_name: '身份信息',
                }, {
                    step_name: '职称信息'
                }, {
                    step_name: '上传证件'
                }, {
                    step_name: '上传身份证'
                },{
                    step_name: '签署协议'
                }],
                formList: [],
                formIndex: 0,
                btnDisabled: false,
                dire: 'front', // 前进:front;后退:back
                short_name: '',
            }
        },
        mounted() {
            this.btnDisabled = false
            this.short_name = this.basicInformation.projects[0].short_name
            // 新瑞白科研项目、慢性常见病学科发展项目，显示知情同意书
            if(['xrb', 'sshy'].includes(this.short_name)){
                this.list[4].step_name = `阅读知情同意书`
            }
            this.init()
            if(this.$route.query.dire) {
                this.dire = this.$route.query.dire
            }
        },
        computed: {
            ...mapGetters(['sub_id']),
            ...mapState(['userId', 'reg_type', 'basicInformation'])
        },
        methods: {
            ...mapActions(['SET_ADD_INFORMATION', 'SET_DOCTOR_ID']),
            async init() {
                console.log(this.reg_type, 'reg_type')
                let res = await projectDoctor()
                if (res.length != 0) {
                    let {
                        hospital_id,
                        city_id,
                        province_id,
                        city_name,
                        province_name,
                        hospital_name,
                        doctor
                    } = res
                    this.doctor = doctor
                    this.hospital_id = hospital_id
                    this.city_id = city_id
                    this.province_id = province_id
                    this.address = province_name && city_name ? province_name + '/' + city_name : ''
                    this.hospital = hospital_name
                    // if (this.reg_type == 0) this.getHospital()
                    const data = await getDoctorForm({
                        params: {
                            doctor_id: doctor.id,
                            subproject_id: this.sub_id
                        }
                    })
                    this.formList = data
                    for (let i in this.formList) {
                        let ele = this.formList[i]
                        if (ele.form.length>0) {
                            for (let j in ele.form) {
                                let e = ele.form[j]
                                if (!e.value) {
                                    e.readonly = 0
                                }
                            }
                        }
                    }
                    if (this.formList[this.formIndex].form.length<1) {
                        let req = {}
                        if (this.dire == 'front') this.next(req)
                        if (this.dire == 'back') this.back()
                    } else {
                        for (let i in data) {
                            if (this.formList[i].form.length<1) {
                                this.list.splice(i, 1)
                            }
                        }
                        this.title = '身份信息'
                        this.isShow = true
                    }
                }
            },
            formConfirm(value,other_hospital) {
                console.log("other_hospital",other_hospital)
                if (this.btnDisabled) {
                    return
                }
                let req = {}
                for(let i in value) {
                    let ele = value[i]
                    req[ele.name] = ele.value
                }
                if(this.short_name == 'llsheq'){  //例例生辉二期项目传入other_hospital字段
                    req.other_hospital = other_hospital
                }
                this.btnDisabled = true
                this.next(req)
            },
            next(req) {
                req.doctor_id = this.userId
                req.subproject_id = this.sub_id
                StepOne(req).then(res => {
                    this.$router.replace({
                        path: '/add_position',
                        query: {
                            project_doctor_id: res.id,
                            dire: 'front'
                        }
                    })
                    this.btnDisabled = false
                }).catch(err => {
                    this.btnDisabled = false
                    Toast(err.response.data.message)
                })

            },
            back() {
                this.$router.replace({
                    path: '/sub_project'
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .sub_add {
        // height: 100%;
        width: 100%;
        min-height: 500px;

        .top {
            width: 100%;
            height: 97px;
            background-image: linear-gradient(to right, #5B9BF0, #3D87EA);
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 14px;
            justify-content: center;

            .steps {
                width: 100%;
                margin-bottom: 17px;
            }

            .remark {
                width: 243px;
                height: 21px;
                background: #006AE1;
                border-radius: 10px;
                font-size: 11px;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 21px;

            }
        }

        .group {
            margin-bottom: 20px;

        }

        .content {

            .title {
                font-size: 15px;
                color: #232323;
                text-align: left;
                padding-left: 16px;
                box-sizing: border-box;
                margin-bottom: 10px;
            }
        }


    }
</style>